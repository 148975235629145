import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/onlyoffice/src/client/packages/login/src/providers/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/packages/login/src/styles/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/onlyoffice/src/client/packages/login/src/utils/registry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toast"] */ "/app/onlyoffice/src/client/packages/shared/components/toast/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/packages/shared/styles/theme.scss");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/share.apple.react.svg");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/share.facebook.react.svg");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/share.google.react.svg");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/share.linkedin.react.svg");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/share.microsoft.react.svg");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/share.zoom.react.svg");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/thirdparties/twitter.svg");
